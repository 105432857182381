@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --font-Inter: 'Inter';
    --font-Exo_2: 'Exo 2';
    --font-Montserrat: 'Montserrat';

    /* Cores PV */
    --LaranjaPonkan-foreground: var(--TextWhite);
    --LaranjaPonkan-300: 31 100% 43%;
    --LaranjaPonkan-400: 31 100% 46%;
    --LaranjaPonkan-500: 31 100% 50%;
    --LaranjaPonkan-600: 31 100% 57%;
    --LaranjaPonkan-700: 31 100% 64%;

    --LavandaIndigo-foreground: var(--TextWhite);
    --LavandaIndigo-300: 248 81% 56%;
    --LavandaIndigo-400: 248 81% 60%;
    --LavandaIndigo-500: 248 81% 65%;
    --LavandaIndigo-600: 248 81% 70%;
    --LavandaIndigo-700: 248 81% 75%;

    --Turquesa-foreground: var(--TextWhite);
    --Turquesa-300: 169 69% 43%;
    --Turquesa-400: 169 69% 47%;
    --Turquesa-500: 169 69% 55%;
    --Turquesa-600: 169 69% 62%;
    --Turquesa-700: 169 70% 68%;

    --BegeAreia-foreground: var(--AzulMidnight-500);
    --BegeAreia-300: 22 44% 86%;
    --BegeAreia-400: 22 44% 90%;
    --BegeAreia-500: 22 100% 96%;
    --BegeAreia-600: 20 23% 97%;
    --BegeAreia-700: 60 11% 98%;

    --AzulMidnight-foreground: var(--TextWhite);
    --AzulMidnight-300: 240 38% 3%;
    --AzulMidnight-400: 235 40% 6%;
    --AzulMidnight-500: 233 41% 9%;
    --AzulMidnight-600: 233 41% 15%;
    --AzulMidnight-700: 233 41% 22%;

    --CinzaPetrolio-foreground: var(--AzulMidnight-500);
    --CinzaPetrolio-500: 233 11% 52%;

    --MagentaSerasa-foreground: var(--TextWhite);
    --MagentaSerasa-300: 332 78% 48%;
    --MagentaSerasa-400: 332 78% 52%;
    --MagentaSerasa-500: 332 78% 56%;
    --MagentaSerasa-600: 332 78% 59%;
    --MagentaSerasa-700: 332 78% 62%;

    --TextWhite: 0 0% 100%;

    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: var(--AzulMidnight-500);
    --primary-foreground: 210 40% 98%;

    --secondary: var(--LaranjaPonkan-500);
    --secondary-foreground: 210 40% 98%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: var(--MagentaSerasa-500);
    --destructive-foreground: var(--foreground);

    --success: var(--Turquesa-500);
    --success-foreground: var(--foreground);

    --border: var(--AzulMidnight-500);
    --input: var(--background);
    --input-foreground: var(--foreground);
    --input-border: var(--BegeAreia-500);
    --ring: var(--AzulMidnight-500);

    --radius: 2rem;

    @media (min-width: 768px) {
      --text-4xl: 3rem;
      --text-line-4xl: 1;
      --text-3xl: 2.5rem;
      --text-line-3xl: 1.2;
      --text-2xl: 1.5rem;
      --text-line-2xl: 1.3;
      --text-xl: 1.125rem;
      --text-line-xl: 1.3;
      --text-lg: 1.125rem;
      --text-line-lg: 0.8;
      --text-base: 1rem;
      --text-line-base: 1.5;
    }
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: var(--AzulMidnight-500);
    --primary-foreground: 210 40% 98%;

    --secondary: var(--LaranjaPonkan-500);
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --border: var(--AzulMidnight-500);
    --input-border: var(--AzulMidnight-500);
    --ring: var(--AzulMidnight-500);
    --overlay-bg: rgba(0, 0, 0, 0.5);
  }

  * {
    @apply border-border;
  }

  svg.cp-icon path {
    stroke-width: var(--svg-stroke-width, 2);
  }

  html,
  body {
    scroll-behavior: smooth;
  }

  body {
    @apply bg-background text-foreground;
    font-family: var(--font-Montserrat);
  }

  .text-4xl {
    font-family: var(--font-Exo_2);
  }
  .text-3xl {
    font-family: var(--font-Exo_2);
  }
  .text-lg {
    text-transform: uppercase;
  }
  .text-xs {
    font-family: var(--font-Inter);
  }
}

.--text-editor.builder-text li > p,
.--text-editor .builder-text li > p,
.--text-editor.builder-text > p,
.--text-editor .builder-text > p {
  @apply my-4;
}
.--text-editor.builder-text > *:first-child,
.--text-editor .builder-text > *:first-child {
  @apply mt-0;
}

.--text-editor.builder-text > ul,
.--text-editor .builder-text > ul,
.--text-editor.builder-text > ol,
.--text-editor .builder-text > ol {
  @apply list-outside my-4 pl-4;
}

.--text-editor.builder-text > ol,
.--text-editor .builder-text > ol {
  @apply list-decimal;
}

.--text-editor.builder-text > ul,
.--text-editor .builder-text > ul {
  @apply list-disc;
}

.--text-editor.builder-text li,
.--text-editor .builder-text li {
  @apply mb-4;
}

.--text-editor.builder-text p a,
.--text-editor .builder-text p a {
  @apply underline font-semibold text-LaranjaPonkan-500;
}

.--text-editor.builder-text li:last-child,
.--text-editor.builder-text li:last-child *:last-child,
.--text-editor.builder-text > *:last-child,
.--text-editor .builder-text > *:last-child {
  @apply mb-0;
}

.--inline-text,
.--inline-text div,
.--inline-text span,
.--inline-text strong,
.--inline-text p,
.--inline-text i,
.--inline-text b,
.--inline-text u {
  display: inline;
}

.--overlay:before {
  content: '';
  display: block;
  background: var(--overlay-bg);
  mix-blend-mode: var(--overlay-mode, normal);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

blockquote {
  @apply pl-5 py-0 ml-10 md:ml-40 md:pl-8 border-l-2 border-LaranjaPonkan-500;
}
blockquote *:first-of-type {
  @apply pt-0 mt-0;
}
blockquote *:last-of-type {
  @apply pb-0 mb-0;
}
